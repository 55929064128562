<template>
  <div>
    <header class="mch__form-header">
      <div class="mch__form-title">{{ $t("piggybank.createModal.addressData") }}</div>
    </header>
    <div class="bb-form-fieldset">
      <InputText v-model="direccion"
                 :label="$t('generic.address')"
                 :placeholder="$t('piggybank.createModal.addressPlaceholder')"
                 v-bind:initialValue="getSecondStepData.direccion"
                 required />
      <InputText v-model="codigoPostal"
        :label="$t('generic.postalCode')"
        :placeholder="$t('piggybank.createModal.postalCodePlaceholder')"
        required
        v-bind:initialValue="getSecondStepData.codigoPostal"
        :inputmode="'numeric'"
        :errorLabel="errorCodigoPostal"
        @input="validateCodigoPostalOnInput()"
      />
      <InputText v-model="ciudad"
                 :label="$t('generic.city')"
                 :placeholder="$t('piggybank.createModal.cityPlaceholder')"
                 required
                 v-bind:initialValue="getSecondStepData.ciudad"
      />

      <InputSelect v-model="provincia" :label="$t('generic.department')" :placeholder="$t('piggybank.createModal.departmentPlaceholder')"
                   :required="provinceIsRequired"
                   v-show="provinceIsRequired"
                   v-bind:initialValue="getSecondStepData.provincia"
                   :options="provincias" />
    </div>

    <footer class="mch__actions">
      <button @click="backStep" class="button button--secondary button--icoStart">
        <i class="uil uil-arrow-left"></i> {{ $t("generic.back") }}
      </button>
      <button @click="nextStep"
              class="button button--primary button--block button--icoEnd"
              :disabled="!isFormValid()">
        {{ $t("generic.next") }} <i class="uil uil-arrow-right"></i>
      </button>
    </footer>
  </div>
</template>

<script>
import InputText from "../../../components/forms/InputText.vue";
import CrearDisclaimerStripe from "./CrearDisclaimer.vue";
import { mapGetters } from "vuex";
import InputSelect from "../../../components/forms/InputSelect.vue";

export default {
  name: "CrearStepDatos",
  components: {
    InputSelect,
    CrearDisclaimerStripe,
    InputText
  },
  computed: {
    ...mapGetters({
      user: "user",
      getFirstStepData: "getFirstStepData",
      getSecondStepData: "getSecondStepData",
      countryCode: "countryCode",
    }),
    provinceIsRequired() {
      return this.countryCode === "ES";
    },
  },
  data() {
    return {
      direccion: "",
      codigoPostal: "",
      ciudad: "",
      provincia: "",
      provincias: [
        { value: "alava", label: "Álava" },
        { value: "albacete", label: "Albacete" },
        { value: "alicante", label: "Alicante" },
        { value: "almeria", label: "Almería" },
        { value: "asturias", label: "Asturias" },
        { value: "avila", label: "Ávila" },
        { value: "badajoz", label: "Badajoz" },
        { value: "barcelona", label: "Barcelona" },
        { value: "burgos", label: "Burgos" },
        { value: "caceres", label: "Cáceres" },
        { value: "cadiz", label: "Cádiz" },
        { value: "cantabria", label: "Cantabria" },
        { value: "castellon", label: "Castellón" },
        { value: "ciudadreal", label: "Ciudad Real" },
        { value: "cordoba", label: "Córdoba" },
        { value: "cuenca", label: "Cuenca" },
        { value: "girona", label: "Girona" },
        { value: "granada", label: "Granada" },
        { value: "guadalajara", label: "Guadalajara" },
        { value: "guipuzcoa", label: "Guipúzcoa" },
        { value: "huelva", label: "Huelva" },
        { value: "huesca", label: "Huesca" },
        { value: "illesbalears", label: "Illes Balears" },
        { value: "jaen", label: "Jaén" },
        { value: "acoruña", label: "A Coruña" },
        { value: "larioja", label: "La Rioja" },
        { value: "leon", label: "León" },
        { value: "lleida", label: "Lleida" },
        { value: "lugo", label: "Lugo" },
        { value: "madrid", label: "Madrid" },
        { value: "malaga", label: "Málaga" },
        { value: "murcia", label: "Murcia" },
        { value: "navarra", label: "Navarra" },
        { value: "ourense", label: "Ourense" },
        { value: "palencia", label: "Palencia" },
        { value: "pontevedra", label: "Pontevedra" },
        { value: "salamanca", label: "Salamanca" },
        { value: "segovia", label: "Segovia" },
        { value: "sevilla", label: "Sevilla" },
        { value: "soria", label: "Soria" },
        { value: "tarragona", label: "Tarragona" },
        { value: "santacruztenerife", label: "Santa Cruz de Tenerife" },
        { value: "teruel", label: "Teruel" },
        { value: "toledo", label: "Toledo" },
        { value: "valencia", label: "Valencia" },
        { value: "valladolid", label: "Valladolid" },
        { value: "vizcaya", label: "Vizcaya" },
        { value: "zamora", label: "Zamora" },
        { value: "zaragoza", label: "Zaragoza" },
        { value: "ceuta", label: "Ceuta" },
        { value: "melilla", label: "Melilla" }
      ],
      errorCodigoPostal: "",
      errorPhoneNumber: "",
    };
  },
  created() {
    this.initFormData();
  },
  methods: {
    async nextStep() {
      const secondStepData = {
        direccion: this.direccion,
        codigoPostal: this.codigoPostal,
        ciudad: this.ciudad,
        provincia: this.provincia,
      };

      if (secondStepData) {
        await this.$store.dispatch("realizarSegundoPaso", secondStepData);
        this.$emit("next-step", 3, secondStepData);
      } else {
        console.error("Data is undefined or null");
      }
    },
    initFormData() {
      if (this.getSecondStepData) {
        this.direccion = this.getSecondStepData.direccion || "";
        this.codigoPostal = this.getSecondStepData.codigoPostal || "";
        this.ciudad = this.getSecondStepData.ciudad || "";
        this.provincia = this.getSecondStepData.provincia || "";
      }
    },
    backStep() {
      this.$emit("next-step", 1);
    },
    validateCodigoPostal() {
      return this.codigoPostal.length === 5;
    },
    validateCodigoPostalOnInput() {
      if (this.codigoPostal.length !== 5) {
        this.errorCodigoPostal = this.$t("piggybank.createModal.postalCodeErrorDigits");
      } else {
        this.errorCodigoPostal = "";
      }
    },
    isFormValid() {
      return this.direccion && this.validateCodigoPostal() && this.ciudad && (
        this.provincia || !this.provinceIsRequired
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
